export const LATEST_NEWS_TEST_IDS = {
  ARTICLE: 'latest-news-article-wrapper',
  DATE: 'latest-news-date',
  LATEST_NEWS: 'latest-news',
  LINK: 'latest-news-link',
  TITLE: 'latest-news-title',
};

export const NEWSLETTERS_TEST_IDS = {
  ARTICLE: 'newsletter-article-wrapper',
  ARTICLE_TITLE: 'newsletter-article-title',
  NEWSLETTERS: 'newsletters',
  SECTION_TITLE: 'newsletter-section-title',
  TITLE: 'newsletter-title',
};

export const FEATURED_SECONDARY_TEST_IDS = {
  FEATURED_SECONDARY: 'featured-secondary',
  TITLE: 'featured-secondary-title',
};

export const FEATURED_PRIMARY_TEST_IDS = {
  FEATURED_PRIMARY: 'featured-primary',
};

export const FEATURED_ARTICLE_TEST_IDS = {
  FEATURED_ARTICLE: 'featured-article',
};

export const SECTIONS_BLOCK_TEST_IDS = {
  SECTION_BLOCK_TITLE: 'section-block-title',
  SECTION_WRAPPER: 'section-block-wrapper',
};

export const IMAGE_TEST_IDS = {
  IMAGE: 'image',
  IMAGE_WRAPPER: 'image-wrapper',
};

export const TEXT_TEST_IDS = {
  HEADING_H2: 'heading-h2',
  TEXT: 'text',
};
