import { MediaItem } from 'interfaces/content/Media';

const parseCaptionWhenAltTextEmpty = (image: MediaItem | string) =>
  (typeof image === 'object' ? image.altText || image.caption?.replace(/<p>|<\/p>/g, '').trim() || '' : '');

// Note: setting this up for now, but ideally we'll have the content cleaned up in backend eventually + remove those empty p/br tags
export const parseContentForEmptySpace = (content: string) =>
  content
    .split('<br>')
    .join('')
    .split('<p>')
    .filter((item) => item !== '&nbsp;</p>')
    .join('<p>');

export default parseCaptionWhenAltTextEmpty;
